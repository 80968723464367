export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
        "exitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my tests"])},
        "exitTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating in Snapshot!"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "nextQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next question"])},
        "questionsLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("numRemaining")), " more Snapshot question(s) remaining."])},
        "readAndUnderstoodInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking this box you confirm that you have read the above instructions. You are now proceeding to the Snapshot interview. Please exit if you are not ready to record your interview at this time."])},
        "startSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Snapshot Interview"])},
        "testDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to complete Snapshot. This is not your practice portion. Clicking the submit button will upload your response. <strong>Responses cannot be re-recorded.</strong>"])},
        "testInstructionsBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<ul><li>You have <strong>", _interpolate(_named("questionsRemaining")), " Snapshot questions remaining</strong> to record your response(s) to. Your responses will be limited to ", _interpolate(_named("answerTime")), " minutes each in length.</li><li>You will only have a ", _interpolate(_named("reflectionTime")), " second opportunity to read and reflect on each question. Once the ", _interpolate(_named("reflectionTime")), " seconds is over you will be redirected to the interview, where the ", _interpolate(_named("answerTime")), " minute timer will start automatically. When you finish recording, your response will be automatically submitted.</li><li><strong>You can only take Snapshot once!</strong> Please ensure that you have a strong internet connection and your testing environment is free of distractions before you begin.</li><li>When you are ready to begin, click 'Start Snapshot'. You will be prompted to give permission to your web camera and microphone in order to record your responses.</li><li><strong>You will not be able to watch your recorded response. Responses cannot be re-recorded.</strong></li><li><strong class='text-red-600'>You are about to complete Snapshot. This is not your practice portion.</strong></li></ul>"])},
        "testInstructionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
        "testTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot Interview"])},
        "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload successful"])}
      },
      "fr": {
        "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])},
        "exitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mes évaluations"])},
        "exitTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir participé à Snapshot !"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivante"])},
        "nextQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question suivante"])},
        "questionsLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il vous reste ", _interpolate(_named("numRemaining")), " question(s) Snapshot."])},
        "readAndUnderstoodInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cochant cette case, vous confirmez avoir lu les instructions ci-dessus. Vous allez maintenant accéder à votre entrevue Snapshot. Veuillez quitter la page si vous n'êtes pas prêt(e) à enregistrer votre entrevue à cet instant."])},
        "startSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l'entrevue Snapshot"])},
        "testDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de compléter Snapshot. Il ne s'agit pas du module d'entraînement. Cliquer sur le bouton « Soumettre » entraînera le téléversement de votre réponse. <strong>Les réponses ne peuvent pas être réenregistrées.</strong>"])},
        "testInstructionsBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<ul><li>Il vous reste <strong>", _interpolate(_named("questionsRemaining")), " questions Snapshot</strong> pour lesquelles vous devez enregistrer votre réponse. Vos réponses sont limitées à ", _interpolate(_named("answerTime")), " minutes chacune.</li><li>Vous aurez seulement ", _interpolate(_named("reflectionTime")), " secondes pour lire et réfléchir à chaque question. Une fois les ", _interpolate(_named("reflectionTime")), " secondes écoulées, vous serez dirigé(e) vers l'entrevue, où l'enregistrement de ", _interpolate(_named("answerTime")), " minutes commencera automatiquement. Lorsque vous avez terminé votre enregistrement, votre réponse est envoyée automatiquement.</li><li><strong>Vous ne pouvez passer Snapshot qu'une seule fois !</strong> Veuillez vous assurer que votre connexion internet est stable et que rien autour de vous ne pourrait vous distraire avant de commencer.</li><li>Quand vous êtes prêt(e), cliquez sur « Commencer Snapshot ». Il vous sera demandé d'autoriser l'accès à votre webcam et à votre microphone afin d'enregistrer vos réponses.</li><li><strong>Vous ne pourrez pas visionner vos réponses enregistrées. Les réponses ne peuvent pas être réenregistrées.</strong></li><li><strong class='text-red-600'>Vous êtes sur le point de compléter Snapshot. Il ne s’agit pas du module d’entraînement.</strong></li></ul>"])},
        "testInstructionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
        "testTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevue Snapshot"])},
        "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement réussi"])}
      }
    }
  })
}
