
import { defineComponent, onBeforeUnmount, PropType, ref, toRef, watch } from 'vue';
import { useI18n } from '@/services/i18n';
import { injectApiClient } from '@/services/apiClient';
import { timerReflectionThreshold, timerAnswerThreshold } from '@/services/util';
import { CountdownTimer, TimerAbortedError } from '@/services/countdownTimer';
import { Question } from '@/interfaces/interfaces';
import { asyncTask } from '@/store/lib/asyncTask';

import VideoRecorderError from '@/components/common/VideoRecorderError.vue';
import videoRecorder from '@/components/mixins/videoRecorder';
import VideoPreview from '@/components/common/VideoPreview.vue';
import Timer from '@/components/common/Timer.vue';
import RecordingIndicator from '@/components/common/RecordingIndicator.vue';
import VideoUploadStatus from '@/components/common/VideoUploadStatus.vue';

export default defineComponent({
  name: 'Question',
  props: {
    question: { required: true, type: Object as PropType<Question> },
    questionIndex: { required: true, type: Number }
  },
  emits: ['uploaded'],
  components: {
    VideoRecorderError,
    VideoPreview,
    Timer,
    RecordingIndicator,
    VideoUploadStatus
  },
  setup(props, { emit }) {
    const client = injectApiClient();
    const i18n = useI18n();
    const mediaError = ref<Error | null>(null);

    const recorder = videoRecorder();
    const { isCapturing, isRecording, codec } = recorder;

    const isUploading = ref(false);
    const percentageUploaded = ref(0);
    const uploadError = ref(false);
    const showSubmitAlert = ref(false);

    const stream = ref<MediaStream | null>(null);
    const videoSrc = ref<{ src: string; type: string } | null>(null);

    let video: Blob | null = null;
    let duration: number | null = null;

    const timer = new CountdownTimer();

    function reset() {
      isUploading.value = false;
      percentageUploaded.value = 0;
      uploadError.value = false;
      mediaError.value = null;
      showSubmitAlert.value = false;
      stream.value = null;
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      videoSrc.value = null;
      video = null;
      duration = null;
      recorder.reset();

      if (timer.isRunning.value) {
        timer.stop();
      }
    }

    async function uploadVideo() {
      isUploading.value = false;
      percentageUploaded.value = 0;
      if (video === null) {
        throw new Error('Video is missing');
      }
      try {
        uploadError.value = false;
        isUploading.value = true;
        const fileName = await client.uploadSnapshotVideo(
          video,
          percentage => (percentageUploaded.value = Math.round(percentage))
        );
        isUploading.value = false;
        emit('uploaded', { fileName, duration });
      } catch (error) {
        isUploading.value = false;
        uploadError.value = true;
        throw error;
      }
    }

    const submitVideo = asyncTask(async () => {
      // We need the duration of the video to submit the Snapshot answer
      ({ video, duration } = await recorder.stopRecording());
      recorder.stopCapturing();

      videoSrc.value = {
        src: window.URL.createObjectURL(video),
        type: codec
      };

      if (timer.isRunning.value) {
        timer.stop();
      }

      await uploadVideo();
    });

    async function onSubmitClicked() {
      if (submitVideo.isRunning) {
        return;
      } else if (showSubmitAlert.value) {
        await submitVideo.run();
        return;
      }
      showSubmitAlert.value = true;
    }

    async function startRecording() {
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      try {
        timer.setup(props.question.answerTime);
        await recorder.startRecording();
        await timer.run();

        if (!submitVideo.isRunning) {
          await submitVideo.run();
        }
      } catch (error) {
        if (!(error instanceof TimerAbortedError)) {
          throw error;
        }
      }
    }

    async function startCapturing() {
      try {
        timer.setup(props.question.reflectionTime);
        stream.value = await recorder.startCapturing();
        mediaError.value = null;
      } catch (error) {
        mediaError.value = error as Error;
        return;
      }
      try {
        await timer.run();
        await startRecording();
      } catch (error) {
        if (!(error instanceof TimerAbortedError)) {
          throw error;
        }
      }
    }

    watch(
      [toRef(props, 'question'), toRef(props, 'questionIndex')],
      async () => {
        reset();
        if (props.questionIndex >= 0) {
          await startCapturing();
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(reset);

    return {
      t: i18n.t,
      i18n,
      mediaError,
      startCapturing,
      stream,
      timeLeft: timer.timeLeft,
      uploadVideo,
      submitVideo,
      isCapturing,
      isRecording,
      isUploading,
      percentageUploaded,
      uploadError,
      onSubmitClicked,
      showSubmitAlert,
      timerReflectionThreshold,
      timerAnswerThreshold,
      timerRecentlyStarted: timer.timerRecentlyStarted
    };
  }
});
