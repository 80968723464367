<template>
  <div v-if="isLoading" class="flex justify-center mt-20">
    <a-spinner size="2rem" variant="primary" />
  </div>
  <div v-else class="flex flex-col screen-height -mb-24">
    <div
      v-if="!isUploaded && !isExit"
      class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto mt-8 px-1"
    >
      <h1 class="text-4xl text-blue-darkest">
        {{ t('testTitle') }}
      </h1>
      <p
        class="mt-3 text-gray-darker"
        role="doc-subtitle"
        v-html="t('testDescription')"
      ></p>
    </div>
    <main class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto mt-8 mb-10">
      <a-collapsible-card
        v-if="isIntro"
        expanded
        fixed
        data-cy="card-test-instructions"
        class="mb-10"
      >
        <h2 class="text-xl text-blue-darkest">{{ t('testInstructionsTitle') }}</h2>
        <template #content>
          <div
            class="prose list-spread mt-4 pr-4"
            v-html="
              t('testInstructionsBody', {
                questionsRemaining,
                answerTime: formattedAnswerTime,
                reflectionTime
              })
            "
          />
          <div class="mt-8 flex flex-col items-center">
            <div>
              <a-checkbox
                v-model="understoodInstructions"
                data-cy="checkbox-understood-instructions"
              >
                {{ t('readAndUnderstoodInstructions') }}
              </a-checkbox>
            </div>
            <div class="mt-8 mb-1">
              <a-button link :to="{ name: 'applicant.home' }" variant="outline">
                {{ t('backToHome') }}
              </a-button>
              <a-button
                variant="primary"
                class="ml-4"
                :disabled="!understoodInstructions"
                @click="goToNextStep()"
                data-cy="button-start-test"
              >
                {{ t('startSnapshot') }}
              </a-button>
            </div>
          </div>
        </template>
      </a-collapsible-card>
      <div v-else-if="!isIntro && !isExit">
        <Question
          :question="currentQuestion"
          :questionIndex="currentQuestionIndex"
          v-if="!isUploaded && currentQuestion"
          @uploaded="submitAnswer($event)"
        />
        <div v-else class="mt-20 mx-auto">
          <SuccessfullyCompleted>
            <h1 data-cy="txt-upload-successful">{{ t('uploadSuccessful') }}</h1>
            <template #content>
              <div class="text-center mt-2">
                <h2 v-if="questionsRemaining > 0">
                  {{ t('questionsLeft', { numRemaining: questionsRemaining }) }}
                </h2>
                <a-button
                  @click="goToNextStep()"
                  class="mt-8"
                  data-cy="button-next-question"
                >
                  {{ questionsRemaining > 0 ? t('nextQuestion') : t('next') }}
                </a-button>
              </div>
            </template>
          </SuccessfullyCompleted>
        </div>
      </div>
      <div v-if="isExit" class="xl:w-11/12 mx-auto mt-12 text-blue-darkest">
        <h1 class="text-2xl mb-10 text-center">{{ t('exitTitle') }}</h1>
        <ApplicantSurvey v-if="survey !== null" :survey="survey" />
        <div v-else class="text-center mt-6">
          <a-button link :to="{ name: 'applicant.selectTest' }">
            {{ t('exitButton') }}
          </a-button>
        </div>
      </div>
    </main>
    <div class="mt-auto border-t border-gray-lighter py-10">
      <StepIndicator
        :steps="completedSteps"
        :active="currentQuestionIndex"
        :intro-completed="!isIntro"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { injectApiClient } from '@/services/apiClient';
import { formatCountdownTime } from '@/services/util';

import { Submission, Survey, Test, TestQuestion } from '@/interfaces/interfaces';

import Question from '@/components/applicant/Question.vue';
import SuccessfullyCompleted from '@/components/common/SuccessfullyCompleted.vue';
import ApplicantSurvey from '@/components/applicant/ApplicantSurvey.vue';
import StepIndicator from '@/components/common/StepIndicator.vue';

export default defineComponent({
  name: 'ApplicantTest',
  components: {
    Question,
    SuccessfullyCompleted,
    ApplicantSurvey,
    StepIndicator
  },
  setup() {
    const applicant = useStore(applicantStore);
    const client = injectApiClient();

    const understoodInstructions = ref(false);

    const isLoading = ref(true);
    const isUploaded = ref(false);

    const test = ref<Test | null>(null);
    const testQuestions = computed(() => test.value?.testQuestions ?? []);
    const submission = ref<Submission | null>(null);
    const answers = computed(() => submission.value?.answers ?? []);

    const survey = ref<Survey | null>(null);

    const questionsWithAnswers = computed(() =>
      testQuestions.value.map(tq => ({
        question: tq.question,
        answer: answers.value.find(a => a.testQuestionId === tq.id)
      }))
    );

    const currentQuestionIndex = ref(-1);
    const isIntro = computed(() => currentQuestionIndex.value === -1);
    const isExit = computed(
      () => currentQuestionIndex.value === testQuestions.value.length
    );

    const currentTestQuestion = computed<TestQuestion | undefined>(
      () => testQuestions.value[currentQuestionIndex.value]
    );
    const currentQuestion = computed(() => currentTestQuestion.value?.question);
    const isCurrentQuestionAnswered = computed(
      () => questionsWithAnswers.value[currentQuestionIndex.value]?.answer !== undefined
    );

    const completedSteps = computed(() =>
      questionsWithAnswers.value.map(qa => qa.answer !== undefined)
    );

    const questionsRemaining = computed(() => {
      let count = 0;
      for (const completed of completedSteps.value) {
        if (!completed) {
          count++;
        }
      }
      return count;
    });

    function goToNextStep() {
      currentQuestionIndex.value++;
      while (
        isCurrentQuestionAnswered.value &&
        currentQuestionIndex.value < testQuestions.value.length
      ) {
        currentQuestionIndex.value++;
      }
      isUploaded.value = false;
    }

    async function submitAnswer(video: { fileName: string; duration: number }) {
      if (currentTestQuestion.value === undefined) {
        throw new Error('Cannot submit answer without current test question');
      }
      const answer = await client.submitAnswer(
        currentTestQuestion.value.id,
        video.fileName,
        video.duration
      );
      submission.value?.answers?.push(answer);
      isUploaded.value = true;
    }

    // Intro info showing question answer time
    const formattedAnswerTime = computed(() => {
      const { minutes, seconds } = formatCountdownTime(
        test.value?.testQuestions?.[0].question.answerTime
      );
      return `${minutes}:${seconds}`;
    });
    const reflectionTime = computed(() =>
      Math.floor((test.value?.testQuestions?.[0].question.reflectionTime ?? 0) / 1000)
    );

    onMounted(async () => {
      isLoading.value = true;
      if (applicant.currentTest === null) {
        throw new Error('No test selected');
      }
      const testDetails = await client.getApplicantTestDetails(applicant.currentTest.id);
      test.value = testDetails.test;
      submission.value = testDetails.submission;
      survey.value = testDetails.test.survey;

      test.value.testQuestions?.sort((a, b) => a.position - b.position);
      survey.value.questionEntries.sort((a, b) => a.position - b.position);
      isLoading.value = false;
    });

    return {
      t: useI18n().t,
      isLoading,
      questionsRemaining,
      isIntro,
      isExit,
      formattedAnswerTime,
      reflectionTime,
      understoodInstructions,
      currentQuestionIndex,
      isUploaded,
      currentQuestion,
      testQuestions,
      completedSteps,
      goToNextStep,
      submitAnswer,
      survey
    };
  }
});
</script>

<style lang="postcss" scoped>
.screen-height {
  min-height: calc(100vh - 3.5rem);
}
</style>

<i18n>
{
  "en": {
    "backToHome": "Back to Home",
    "exitButton": "Back to my tests",
    "exitTitle": "Thank you for participating in Snapshot!",
    "next": "Next",
    "nextQuestion": "Next question",
    "questionsLeft": "You have {numRemaining} more Snapshot question(s) remaining.",
    "readAndUnderstoodInstructions": "By clicking this box you confirm that you have read the above instructions. You are now proceeding to the Snapshot interview. Please exit if you are not ready to record your interview at this time.",
    "startSnapshot": "Start Snapshot Interview",
    "testDescription": "You are about to complete Snapshot. This is not your practice portion. Clicking the submit button will upload your response. <strong>Responses cannot be re-recorded.</strong>",
    "testInstructionsBody": "<ul><li>You have <strong>{questionsRemaining} Snapshot questions remaining</strong> to record your response(s) to. Your responses will be limited to {answerTime} minutes each in length.</li><li>You will only have a {reflectionTime} second opportunity to read and reflect on each question. Once the {reflectionTime} seconds is over you will be redirected to the interview, where the {answerTime} minute timer will start automatically. When you finish recording, your response will be automatically submitted.</li><li><strong>You can only take Snapshot once!</strong> Please ensure that you have a strong internet connection and your testing environment is free of distractions before you begin.</li><li>When you are ready to begin, click 'Start Snapshot'. You will be prompted to give permission to your web camera and microphone in order to record your responses.</li><li><strong>You will not be able to watch your recorded response. Responses cannot be re-recorded.</strong></li><li><strong class='text-red-600'>You are about to complete Snapshot. This is not your practice portion.</strong></li></ul>",
    "testInstructionsTitle": "Instructions",
    "testTitle": "Snapshot Interview",
    "uploadSuccessful": "Upload successful"
  },
  "fr": {
    "backToHome": "Retourner à l'accueil",
    "exitButton": "Retourner à mes évaluations",
    "exitTitle": "Merci d'avoir participé à Snapshot !",
    "next": "Suivante",
    "nextQuestion": "Question suivante",
    "questionsLeft": "Il vous reste {numRemaining} question(s) Snapshot.",
    "readAndUnderstoodInstructions": "En cochant cette case, vous confirmez avoir lu les instructions ci-dessus. Vous allez maintenant accéder à votre entrevue Snapshot. Veuillez quitter la page si vous n'êtes pas prêt(e) à enregistrer votre entrevue à cet instant.",
    "startSnapshot": "Commencer l'entrevue Snapshot",
    "testDescription": "Vous êtes sur le point de compléter Snapshot. Il ne s'agit pas du module d'entraînement. Cliquer sur le bouton « Soumettre » entraînera le téléversement de votre réponse. <strong>Les réponses ne peuvent pas être réenregistrées.</strong>",
    "testInstructionsBody": "<ul><li>Il vous reste <strong>{questionsRemaining} questions Snapshot</strong> pour lesquelles vous devez enregistrer votre réponse. Vos réponses sont limitées à {answerTime} minutes chacune.</li><li>Vous aurez seulement {reflectionTime} secondes pour lire et réfléchir à chaque question. Une fois les {reflectionTime} secondes écoulées, vous serez dirigé(e) vers l'entrevue, où l'enregistrement de {answerTime} minutes commencera automatiquement. Lorsque vous avez terminé votre enregistrement, votre réponse est envoyée automatiquement.</li><li><strong>Vous ne pouvez passer Snapshot qu'une seule fois !</strong> Veuillez vous assurer que votre connexion internet est stable et que rien autour de vous ne pourrait vous distraire avant de commencer.</li><li>Quand vous êtes prêt(e), cliquez sur « Commencer Snapshot ». Il vous sera demandé d'autoriser l'accès à votre webcam et à votre microphone afin d'enregistrer vos réponses.</li><li><strong>Vous ne pourrez pas visionner vos réponses enregistrées. Les réponses ne peuvent pas être réenregistrées.</strong></li><li><strong class='text-red-600'>Vous êtes sur le point de compléter Snapshot. Il ne s’agit pas du module d’entraînement.</strong></li></ul>",
    "testInstructionsTitle": "Instructions",
    "testTitle": "Entrevue Snapshot",
    "uploadSuccessful": "Téléversement réussi"
  }
}
</i18n>
