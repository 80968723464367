export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro"])},
        "srExitActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit not completed, current item"])},
        "srExitCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit completed"])},
        "srExitNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit not completed"])},
        "srIntroActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro not completed, current item"])},
        "srIntroCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro completed"])},
        "srIntroNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro not completed"])},
        "srProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress indicator"])},
        "srStepActive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("step")), " not completed, current item"])},
        "srStepCompleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("step")), " completed"])},
        "srStepNotCompleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("step")), " not completed"])}
      },
      "fr": {
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro"])},
        "srExitActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin non terminée, section actuelle"])},
        "srExitCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin terminée"])},
        "srExitNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin non terminée"])},
        "srIntroActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro non terminée, section actuelle"])},
        "srIntroCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro terminée"])},
        "srIntroNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro non terminée"])},
        "srProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre d'avancement"])},
        "srStepActive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("step")), " non terminée, section actuelle"])},
        "srStepCompleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("step")), " terminée"])},
        "srStepNotCompleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("step")), " terminée"])}
      }
    }
  })
}
