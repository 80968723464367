
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { injectApiClient } from '@/services/apiClient';
import { injectRouter } from '@/router';

import { Survey, SurveyQuestionEntry } from '@/interfaces/interfaces';

export default defineComponent({
  name: 'ApplicantSurvey',
  props: {
    survey: {
      required: true,
      type: Object as PropType<Survey>
    }
  },

  setup(props) {
    const applicant = useStore(applicantStore);
    const client = injectApiClient();
    const router = injectRouter();

    const testLang = computed(() => applicant.currentTest?.lang ?? 'en');

    const questionEntries = computed(() => props.survey.questionEntries ?? []);
    const answers: Ref<Array<string | number>> = ref([]);
    const surveySubmission = computed(() =>
      answers.value.map((a, index) => ({
        questionEntryId: questionEntries.value[index].id,
        value: a
      }))
    );

    function isHorizontal(questionEntry: SurveyQuestionEntry) {
      if (
        questionEntry.question.type === 'choice' &&
        questionEntry.question.options !== null
      ) {
        return questionEntry.question.options.every(o => o[testLang.value].length < 5);
      }
      return false;
    }

    async function submitSurvey(event: Event) {
      event.preventDefault();
      await client.submitSurveyAnswers(props.survey.id, surveySubmission.value);
      await router.push({ name: 'applicant.selectTest' });
    }

    return {
      t: useI18n().t,
      questionEntries,
      testLang,
      answers,
      submitSurvey,
      isHorizontal
    };
  }
});
