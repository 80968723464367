
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { injectApiClient } from '@/services/apiClient';
import { formatCountdownTime } from '@/services/util';

import { Submission, Survey, Test, TestQuestion } from '@/interfaces/interfaces';

import Question from '@/components/applicant/Question.vue';
import SuccessfullyCompleted from '@/components/common/SuccessfullyCompleted.vue';
import ApplicantSurvey from '@/components/applicant/ApplicantSurvey.vue';
import StepIndicator from '@/components/common/StepIndicator.vue';

export default defineComponent({
  name: 'ApplicantTest',
  components: {
    Question,
    SuccessfullyCompleted,
    ApplicantSurvey,
    StepIndicator
  },
  setup() {
    const applicant = useStore(applicantStore);
    const client = injectApiClient();

    const understoodInstructions = ref(false);

    const isLoading = ref(true);
    const isUploaded = ref(false);

    const test = ref<Test | null>(null);
    const testQuestions = computed(() => test.value?.testQuestions ?? []);
    const submission = ref<Submission | null>(null);
    const answers = computed(() => submission.value?.answers ?? []);

    const survey = ref<Survey | null>(null);

    const questionsWithAnswers = computed(() =>
      testQuestions.value.map(tq => ({
        question: tq.question,
        answer: answers.value.find(a => a.testQuestionId === tq.id)
      }))
    );

    const currentQuestionIndex = ref(-1);
    const isIntro = computed(() => currentQuestionIndex.value === -1);
    const isExit = computed(
      () => currentQuestionIndex.value === testQuestions.value.length
    );

    const currentTestQuestion = computed<TestQuestion | undefined>(
      () => testQuestions.value[currentQuestionIndex.value]
    );
    const currentQuestion = computed(() => currentTestQuestion.value?.question);
    const isCurrentQuestionAnswered = computed(
      () => questionsWithAnswers.value[currentQuestionIndex.value]?.answer !== undefined
    );

    const completedSteps = computed(() =>
      questionsWithAnswers.value.map(qa => qa.answer !== undefined)
    );

    const questionsRemaining = computed(() => {
      let count = 0;
      for (const completed of completedSteps.value) {
        if (!completed) {
          count++;
        }
      }
      return count;
    });

    function goToNextStep() {
      currentQuestionIndex.value++;
      while (
        isCurrentQuestionAnswered.value &&
        currentQuestionIndex.value < testQuestions.value.length
      ) {
        currentQuestionIndex.value++;
      }
      isUploaded.value = false;
    }

    async function submitAnswer(video: { fileName: string; duration: number }) {
      if (currentTestQuestion.value === undefined) {
        throw new Error('Cannot submit answer without current test question');
      }
      const answer = await client.submitAnswer(
        currentTestQuestion.value.id,
        video.fileName,
        video.duration
      );
      submission.value?.answers?.push(answer);
      isUploaded.value = true;
    }

    // Intro info showing question answer time
    const formattedAnswerTime = computed(() => {
      const { minutes, seconds } = formatCountdownTime(
        test.value?.testQuestions?.[0].question.answerTime
      );
      return `${minutes}:${seconds}`;
    });
    const reflectionTime = computed(() =>
      Math.floor((test.value?.testQuestions?.[0].question.reflectionTime ?? 0) / 1000)
    );

    onMounted(async () => {
      isLoading.value = true;
      if (applicant.currentTest === null) {
        throw new Error('No test selected');
      }
      const testDetails = await client.getApplicantTestDetails(applicant.currentTest.id);
      test.value = testDetails.test;
      submission.value = testDetails.submission;
      survey.value = testDetails.test.survey;

      test.value.testQuestions?.sort((a, b) => a.position - b.position);
      survey.value.questionEntries.sort((a, b) => a.position - b.position);
      isLoading.value = false;
    });

    return {
      t: useI18n().t,
      isLoading,
      questionsRemaining,
      isIntro,
      isExit,
      formattedAnswerTime,
      reflectionTime,
      understoodInstructions,
      currentQuestionIndex,
      isUploaded,
      currentQuestion,
      testQuestions,
      completedSteps,
      goToNextStep,
      submitAnswer,
      survey
    };
  }
});
