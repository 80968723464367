<template>
  <ol
    :aria-label="t('srProgress')"
    class="flex justify-center text-sm leading-none"
    data-cy="step-indicator"
  >
    <li
      :class="{ active: active === -1, completed: introCompleted }"
      data-cy="step-indicator-intro"
    >
      <p v-if="!introCompleted">
        <span aria-hidden="true">{{ t('intro') }}</span>
        <span v-if="active === -1" class="sr-only">
          {{ t('srIntroActive') }}
        </span>
        <span v-else class="sr-only">{{ t('srIntroNotCompleted') }}</span>
      </p>
      <div v-else>
        <Checkmark aria-hidden="true" class="w-4 text-blue" />
        <span class="sr-only">{{ t('srIntroCompleted') }}</span>
      </div>
    </li>

    <li
      class="step-number ml-10"
      v-for="(step, index) in steps"
      :class="{ active: active === index, completed: step }"
      :key="index"
      data-cy="step-indicator-step-number"
    >
      <p v-if="!step">
        <span aria-hidden="true">{{ index + 1 }}</span>
        <span v-if="active === index" class="sr-only">
          {{ t('srStepActive', { step: index + 1 }) }}
        </span>
        <span v-else class="sr-only">
          {{ t('srStepNotCompleted', { step: index + 1 }) }}
        </span>
      </p>
      <div v-else>
        <Checkmark aria-hidden="true" class="w-4 text-blue" />
        <span class="sr-only">{{ t('srStepCompleted', { step: index + 1 }) }}</span>
      </div>
    </li>

    <li
      class="ml-10"
      :class="{
        active: active === steps?.length,
        completed: exitCompleted
      }"
      data-cy="step-indicator-exit"
    >
      <p v-if="!exitCompleted">
        <span aria-hidden="true">{{ t('exit') }}</span>
        <span v-if="active === steps?.length" class="sr-only">
          {{ t('srExitActive') }}
        </span>
        <span class="sr-only">{{ t('srExitNotCompleted') }}</span>
      </p>
      <div v-else>
        <Checkmark aria-hidden="true" class="w-4 text-blue" />
        <span class="sr-only">{{ t('srExitCompleted') }}</span>
      </div>
    </li>
  </ol>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';
import Checkmark from '@/components/common/Checkmark.vue';

export default defineComponent({
  name: 'StepIndicator',
  components: { Checkmark },
  props: {
    /**
     * An array of booleans that represent each step and its completion state
     */
    steps: Array,
    /**
     * -1 represents the intro bubble
     * numbers from 0 onwards will represent either the current index of the step or the length of the array
     * (which will in turn represent the exit bubble)
     */
    active: Number,
    introCompleted: { type: Boolean, required: false },
    exitCompleted: { type: Boolean, required: false }
  },

  setup() {
    return {
      t: useI18n().t
    };
  }
});
</script>

<style lang="postcss" scoped>
li {
  @apply flex justify-center items-center text-gray-dark bg-white;
  @apply border-2 border-gray-dark relative p-0.5 rounded-full;
  min-height: 1.5rem;
}

.step-number {
  min-width: 1.5rem;
}

.active,
.completed {
  @apply text-blue border-2 border-blue;
}

.active {
  @apply bg-blue-lighter;
}

li:first-child,
li:last-child {
  min-width: 3.5rem;
}

li::after {
  @apply absolute block w-12 h-0.5 bg-gray-light left-full;
  content: '';
  z-index: -1;
}

li:last-child::after {
  @apply hidden;
}
</style>

<i18n>
{
  "en": {
    "exit": "Exit",
    "intro": "Intro",
    "srExitActive": "Exit not completed, current item",
    "srExitCompleted": "Exit completed",
    "srExitNotCompleted": "Exit not completed",
    "srIntroActive": "Intro not completed, current item",
    "srIntroCompleted": "Intro completed",
    "srIntroNotCompleted": "Intro not completed",
    "srProgress": "Progress indicator",
    "srStepActive": "Question {step} not completed, current item",
    "srStepCompleted": "Question {step} completed",
    "srStepNotCompleted": "Question {step} not completed"
  },
  "fr": {
    "exit": "Fin",
    "intro": "Intro",
    "srExitActive": "Fin non terminée, section actuelle",
    "srExitCompleted": "Fin terminée",
    "srExitNotCompleted": "Fin non terminée",
    "srIntroActive": "Intro non terminée, section actuelle",
    "srIntroCompleted": "Intro terminée",
    "srIntroNotCompleted": "Intro non terminée",
    "srProgress": "Barre d'avancement",
    "srStepActive": "Question {step} non terminée, section actuelle",
    "srStepCompleted": "Question {step} terminée",
    "srStepNotCompleted": "Question {step} terminée"
  }
}
</i18n>
