<template>
  <form @submit="submitSurvey($event)">
    <div
      v-for="(questionEntry, questionIndex) in questionEntries"
      :key="questionEntry.id"
    >
      <div v-if="questionEntry.question.type === 'choice'" class="mb-8">
        <p class="font-bold" v-html="questionEntry.question.text[testLang]"></p>
        <div class="mt-3" :class="{ horizontal: isHorizontal(questionEntry) }">
          <div
            v-for="(option, optionIndex) in questionEntry.question.options"
            :key="optionIndex"
            class="mt-2"
          >
            <a-radio-button
              :value="optionIndex"
              :name="questionEntry.id"
              v-model="answers[questionIndex]"
              required
              data-cy="choice-question"
            >
              {{ option[testLang] }}
            </a-radio-button>
          </div>
        </div>
      </div>

      <div v-else>
        <label :for="questionEntry.id" class="font-bold">
          {{ questionEntry.question.text[testLang] }}
        </label>
        <textarea
          :id="questionEntry.id"
          rows="10"
          class="textarea"
          v-model="answers[questionIndex]"
          data-cy="text-question"
        ></textarea>
      </div>
    </div>

    <div class="text-center">
      <a-button data-cy="button-submit">{{ t('submit') }}</a-button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { injectApiClient } from '@/services/apiClient';
import { injectRouter } from '@/router';

import { Survey, SurveyQuestionEntry } from '@/interfaces/interfaces';

export default defineComponent({
  name: 'ApplicantSurvey',
  props: {
    survey: {
      required: true,
      type: Object as PropType<Survey>
    }
  },

  setup(props) {
    const applicant = useStore(applicantStore);
    const client = injectApiClient();
    const router = injectRouter();

    const testLang = computed(() => applicant.currentTest?.lang ?? 'en');

    const questionEntries = computed(() => props.survey.questionEntries ?? []);
    const answers: Ref<Array<string | number>> = ref([]);
    const surveySubmission = computed(() =>
      answers.value.map((a, index) => ({
        questionEntryId: questionEntries.value[index].id,
        value: a
      }))
    );

    function isHorizontal(questionEntry: SurveyQuestionEntry) {
      if (
        questionEntry.question.type === 'choice' &&
        questionEntry.question.options !== null
      ) {
        return questionEntry.question.options.every(o => o[testLang.value].length < 5);
      }
      return false;
    }

    async function submitSurvey(event: Event) {
      event.preventDefault();
      await client.submitSurveyAnswers(props.survey.id, surveySubmission.value);
      await router.push({ name: 'applicant.selectTest' });
    }

    return {
      t: useI18n().t,
      questionEntries,
      testLang,
      answers,
      submitSurvey,
      isHorizontal
    };
  }
});
</script>

<style lang="postcss" scoped>
.textarea {
  @apply border border-gray block w-full h-32 p-2 mt-3 mb-6 rounded-sm;

  &:hover {
    @apply outline-none ring-1 ring-blue;
  }

  &:focus {
    @apply outline-none;
    box-shadow: 0 0 6px #4c55a5;
  }
}

.horizontal {
  @apply flex flex-wrap;
  & > * {
    @apply mr-8;
  }
}
</style>

<i18n>
{
  "en": {
    "submit": "Submit"
  },
  "fr": {
    "submit": "Soumettre"
  }
}
</i18n>
