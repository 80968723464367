
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';
import Checkmark from '@/components/common/Checkmark.vue';

export default defineComponent({
  name: 'StepIndicator',
  components: { Checkmark },
  props: {
    /**
     * An array of booleans that represent each step and its completion state
     */
    steps: Array,
    /**
     * -1 represents the intro bubble
     * numbers from 0 onwards will represent either the current index of the step or the length of the array
     * (which will in turn represent the exit bubble)
     */
    active: Number,
    introCompleted: { type: Boolean, required: false },
    exitCompleted: { type: Boolean, required: false }
  },

  setup() {
    return {
      t: useI18n().t
    };
  }
});
